import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { auth } from "../data/firebase";
import LoadingBackdrop from "./loadingBackdrop";
import { useSelector } from "react-redux";

import MissingField from "./missingField";

const ProtectedRoute = ({ children, component }) => {
  const { user: localUser } = useSelector((state) => state.auth);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const profileRoute = "/profile/account";

  const isProfileRoute = location.pathname === profileRoute;

  const fieldsToFill = [
    "ghanaCard",
    "ahpcID",
    "staffId",
    "eduHistory",
    "employmentHistory",
    "workPracticeSpecialty",
    "kinContact",
    "nextOfKin",
  ];

  const emptyFields = fieldsToFill.filter((field) => {
    if (localUser[field] === undefined || localUser[field] === "") {
      return true;
    } else if (
      Array.isArray(localUser[field]) &&
      localUser[field].length === 0
    ) {
      return true;
    }
    return false;
  });

  if (!localUser.activated || !user) {
    if (loading) {
      return <LoadingBackdrop />;
    }

    if (component) {
      return component;
    }

    return <Navigate to="/login" replace />;
  } else if (localUser.activated && isProfileRoute) {
    return children;
  } else if (localUser.activated && emptyFields.length !== 0) {
    return <MissingField navigate={navigate} />;
  }

  return children;
};

export default ProtectedRoute;
