import { Book } from "../assets";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../constants";
import IconedText from "./iconedText";

const LearningCard = ({ data }) => {
  const navigate = useNavigate();
  const { other, status, id, name } = data;
  const [pos, setPos] = useState("50%");

  const handleClick = useCallback(
    () => navigate(`${routePaths.learning}/${id}`),
    [navigate, id]
  );
  return (
    <Paper
      className={"parento"}
      onMouseEnter={() => setPos("70%")}
      onMouseLeave={() => setPos("50%")}
      elevation={0}
      onClick={handleClick}
      sx={{
        borderRadius: "20px",
        height: "400px",
        width: { xs: "100%", md: 300 },
      }}
    >
      <Box className={"childo bg-two"}></Box>
      <Typography
        variant={"h6"}
        color={"white"}
        fontWeight={"bold"}
        sx={{
          zIndex: 99,
          position: "absolute",
          top: { xs: "50%", md: pos },
          left: 0,
          bottom: 0,
          right: 0,
          mx: 2,
          textShadow: " 0px 1px 2px #000000CB",
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
      <Box>
        <Stack
          flex={1}
          direction={"row"}
          justifyContent={"space-between"}
          p={2}
        >
          <IconedText
            text={`${other} Course${other !== 1 ? "s" : ""}`}
            icon={<Book />}
          />

          <Typography>{status}</Typography>
        </Stack>

        <Box flexGrow={1} bgcolor="pink">
          <Button
            variant="contained"
            sx={{
              minWidth: "100%",
              textTransform: "inherit",
              fontWeight: "bold",
            }}
            disableElevation
          >
            {"Start Learning"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default LearningCard;
