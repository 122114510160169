import countryList from "react-select-country-list";
import {
  Blog5,
  Blog4,
  Blog3,
  Blog2,
  Blog1,
  CpdIcon,
  WelfareIcon,
  TravelIcon,
  ClearanceIcon,
  Metropolis,
  Phyllps,
  Floke,
  Sysmex,
  Society,
  Melpwu,
  Christland,
  Thermo,
  Benburto,
  Ghs,
  Dcl,
  Ecp,
  CongressBannerLight,
  CongressCard,
  AccountCircleOutlined,
  EventAvailableOutlinedIcon,
  TransgenderOutlinedIcon,
  PeopleOutlinedIcon,
  OutlinedFlagTwoToneIcon,
  BusinessOutlinedIcon,
  CallOutlinedIcon,
  EmailOutlined,
  HttpsOutlinedIcon,
  VisibilityOff,
} from "../assets/index";
import { jobQualification } from "./appConstants";
import {
  eventRegSchema,
  resetInitialValidationSchema,
} from "../utils/validationSchema";

const regions = [
  "Ahafo Region",
  "Ashanti Region",
  "Bono Region",
  "Bono-East Region",
  "Central Region",
  "Eastern Region",
  "Greater Accra Region",
  "North-East Region",
  "Northern Region",
  "Oti Region",
  "Savannah Region",
  "Upper-East Region",
  "Upper-West Region",
  "Volta Region",
  "Western Region",
  "Western-North Region",
];
// const regions = [
//   "Ahafo Region",
//   "Ashanti Region",
//   "Bono-East Region",
//   "Brong Ahafo Region",
//   "Central Region",
//   "Eastern Region",
//   "Greater Accra Region",
//   "North-East Region",
//   "Northern Region",
//   "Oti Region",
//   "Savannah Region",
//   "Upper-East Region",
//   "Upper-West Region",
//   "Western Region",
//   "Western-North Region",
//   "Volta Region",
// ];
const options = () => countryList().getLabels();
const steps = [
  "To foster the spirit of brotherliness and unity among all medical laboratory professionals in Ghana and the world at large.",
  "To promote the science and to develop the practice of Medical Laboratory Science for the benefit of mankind and to collaborate with other health professionals to achieve optimal healthcare delivery in Ghana.",
  "To maintain the honour, independence, dignity and professionalism of all medical laboratory professionals in Ghana and to cater for the social, economic and professional needs of the members.",
  "To collaborate with the government and other relevant bodies or agencies in ensuring that medical laboratory services are comparable to best global practices.",
  "To exercise professional supervision over members with the view of maintaining high standards of professional competence and conduct.",
  "To hold meetings for the delivery of lectures, reading of scientific papers and discussion of scientific and technical matters as well as other subjects of general interest.",
  "To issue on request, copies of lectures and professional transactions to members and others interested in medical laboratory science.",
];

const news = [
  {
    id: "news1",
    date: "14/04/2023",
    category: "GAMLS News",
    title: "News Title",
    desc: "The quick, brown fox jumps over a…",
    image: "",
  },
  {
    id: "news2",
    date: "14/04/2023",
    category: "GAMLS News",
    title: "News Title",
    desc: "The quick, brown fox jumps over a…",
    image: "",
  },
  {
    id: "news3",
    date: "14/04/2023",
    category: "GAMLS News",
    title: "News Title",
    desc: "The quick, brown fox jumps over a…",
    image: "",
  },
];

const partners = [
  Sysmex,
  Floke,
  Phyllps,
  Metropolis,
  Society,
  Melpwu,
  Christland,
  Ghs,
  Thermo,
  Benburto,
  Dcl,
  Ecp,
];

const blogs = [
  {
    id: "news1",
    date: "14/04/2023",
    author: "Author Name",
    title: "BLOG MESSAGE TITTLE",
    image: Blog1,
  },
  {
    id: "news2",
    date: "14/04/2023",
    author: "Author Name",
    title: "BLOG MESSAGE TITTLE",
    image: Blog2,
  },
  {
    id: "news3",
    date: "14/04/2023",
    author: "Author Name",
    title: "BLOG MESSAGE TITTLE",
    image: Blog3,
  },
  {
    id: "news4",
    date: "14/04/2023",
    author: "Author Name",
    title: "BLOG MESSAGE TITTLE",
    image: Blog4,
  },
  {
    id: "news5",
    date: "14/04/2023",
    author: "Author Name",
    title: "BLOG MESSAGE TITTLE",
    image: Blog5,
  },
];

const placeHolderText = {
  long: "The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt fox. Bright vixens jump; quiz.",
  medium:
    "Few quips galvanized the mock jury box. Quick brown dogs jump over the lazy fox. The jay, pig, fox, zebra, and my wolves quack! Blowzy red vixens fight for a quick jump.",
  short:
    "Few quips galvanized the mock jury box. Quick brown dogs jump over the lazy fox.",
};

const routePaths = {
  home: "/",
  about: "/about",
  branches: "/branches",
  login: "/login",
  reset: "/reset",
  news: "/news",
  newsDetails: "/news/:id",
  fund: "/fund",
  resources: "/resources",
  formation: "/about/formation",
  mission: "/about/mission-and-vision",
  executive: "/about/executive-council",
  events: "/events",
  event: "/events/:id",
  jobs: "/jobs",
  job: "/jobs/:id",
  orgs: "/affiliate-orgs",
  contact: "/contact-us",
  faq: "/faq",
  profile: "/profile/:id",
  elections: "/election-hq/",
  electionSection: "/election-hq/:id",
  electionsLogin: "/election-hq/login",
  help: "/help",
  learning: "/learning",
  learningDetails: "/learning/:id",
  moodle: "https://learning.gamls.org/my",
};

const pages = [
  { name: "Branches", path: routePaths.branches },
  { name: "News & PR", path: routePaths.news },
  { name: "GAMLS Fund", path: routePaths.fund },
  { name: "Learning", path: "/learning" },
  { name: "Resources", path: routePaths.resources },
];

const about = [
  { name: "Our Formation", path: routePaths.formation },
  { name: "Mission and Vision", path: routePaths.mission },
  { name: "Executive Council", path: routePaths.executive },
];

const executives = [
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
  { name: "Name of Executive", position: "Position", image: "imagesrc" },
];

const past_presidents = [
  {
    group: [
      { name: "Dr. Abu Abudu Rahamani", start: "2020", end: "Present" },
      {
        name: "Dr. Ignatius Nchor Abowini Awinibuno",
        start: "2018",
        end: "2020",
      },
      {
        name: "Dr. Ignatius Nchor Abowini Awinibuno",
        start: "2016",
        end: "2018",
      },
      { name: "Thomas Kwabena Gyampomah", start: "2014", end: "2016" },
    ],
  },
  {
    group: [
      { name: "Dr. Prince Sodoke Amuzu", start: "2012", end: "2014" },
      { name: "Dr. Prince Sodoke Amuzu", start: "2010", end: "2012" },
      {
        name: "Professor Andrew Anthony Adjei",
        start: "2002",
        end: "2008",
      },
      {
        name: "Professor Clement Opoku-Okrah",
        start: "1998",
        end: "2002",
      },
      {
        name: "David Tete-Donkor",
        start: "1995",
        end: "1998",
      },
    ],
  },
  {
    group: [
      {
        name: "Captain Mensah-Kufour",
        start: "1989",
        end: "1995",
      },
      {
        name: "Clement Bannerman-Williams",
        start: "1977",
        end: "1989",
      },
      {
        name: "Clement Bannerman-Williams",
        start: "1975",
        end: "1977",
      },
      {
        name: "Isaac Adama Sackey",
        start: "1971",
        end: "1975",
      },
    ],
  },
];

const meetingDays = [
  { day: "Monday", time: "22Hrs" },
  { day: "Tuesday", time: "32Hrs" },
  { day: "Wednesday", time: "10Hrs" },
  { day: "Thursday", time: "15Hrs" },
];

const resources = [
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
  { title: "Resource Title", name: "Resource Name" },
];

const events = [
  {
    id: 1,
    image: CongressBannerLight,
    cardImage: CongressCard,
    admission: "Members",
    title: "2022 ANNUAL NATIONAL CONGRESS",
    about:
      "National Congress is an event organised by GALMS every 2 years. Aimed to elect new leaders for the Association and share updates related to the association.",
    theme: "Capacity Enhancement (Medical Laboratory Practice in Ghana)",
    contact: "Regional Secretariat",
    starts: "Wed, Oct 5th",
    ends: "Sat, Oct 8th",
    location: "Noda Hotel, Fumesua - Kumasi, Ashanti Region",
  },
];

const mission_vision = {
  mission:
    "The mission of the association shall be to promote and improve the practice of the medical laboratory profession through eduction, research and training of medical professionals in accordance with international standards for the benefit of humanity.",
  vision:
    "The vision of the association is to be able to promote the efficient practice of medical laboratory science profession",
};

const composition = [
  "General Assembly",
  "National Executive Council",
  "National Executive Officers",
  "Regional Branches",
  "Public Sector Practitioners",
  "Private Sector Practitioners",
  "Association of Private Medical Laboratories, Ghana",
  "Academics and Research Sector Group",
  "GAMLS Women’s Wing",
  "Council of Elders",
  "Statutory Committees",
  "National Secretariat",
];

const memberBenefits = [
  {
    icon: CpdIcon,
    title: "Free CPD's",
    desc: "Get access CPD resources for members in good standing",
  },
  {
    icon: ClearanceIcon,
    title: "Financial Clearance",
    desc: "Facilitation to Financial Clearance by AHPC/MOH",
  },
  {
    icon: WelfareIcon,
    title: "Welfare",
    desc: "Exciting welfare packages for all members",
  },

  {
    icon: TravelIcon,
    title: "Travel Clearance",
    desc: "Verification of Membership for International Travels/Work",
  },
];

const registrationSteps = [
  {
    title: "Bio Data",
    data: [
      {
        placeholder: "First Name",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "firstName",
      },
      {
        placeholder: "Last Name",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "lastName",
      },
      {
        placeholder: "Gender",
        icon: <TransgenderOutlinedIcon />,
        type: "text",
        id: "gender",
        menu: ["Male", "Female"],
      },
      {
        placeholder: "Date of birth",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "birthDate",
      },
      {
        placeholder: "Marital Status",
        icon: <PeopleOutlinedIcon />,
        type: "text",
        id: "maritalStatus",
        menu: ["Single", "Married", "Divorced"],
      },
    ],
  },
  {
    title: "Contact & Demogaphics",
    data: [
      {
        placeholder: "Region",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "region",
        menu: regions,
      },
      {
        placeholder: "Nationality",
        icon: <OutlinedFlagTwoToneIcon />,
        type: "text",
        id: "nationality",
        menu: ["Ghana", "Nigeria", "Togo"],
      },

      {
        placeholder: "Permanent/Home Address",
        icon: <BusinessOutlinedIcon />,
        type: "text",
        id: "address",
      },
      {
        placeholder: "Mobile/Cell Phone No",
        icon: <CallOutlinedIcon />,
        type: "tel",
        id: "phone",
      },
      {
        placeholder: "Ghana Card ID Number",
        icon: <CallOutlinedIcon />,
        type: "text",
        id: "ghanaCard",
        isRequired: true,
      },

      {
        placeholder: "Contact of Next of Kin",
        icon: <CallOutlinedIcon />,
        type: "text",
        id: "kinContact",
        isRequired: true,
      },
      {
        placeholder: "Name of Next of Kin",
        icon: <CallOutlinedIcon />,
        type: "text",
        id: "nextOfKin",
        isRequired: true,
      },
    ],
  },
  {
    title: "Work Information",
    data: [
      {
        placeholder: "Academic Level",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "academicLevel",
        menu: [
          "Cetificate",
          "Diploma",
          "Degree/MLS. D",
          "Masters",
          "PHD Holders",
          "Fellows",
        ],
      },
      {
        placeholder: "Work Speciality",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "specialty",
      },
      {
        placeholder: "Current Employer",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "currentEmployer",
      },
      {
        placeholder: "Current Employer’s Address",
        icon: <BusinessOutlinedIcon />,
        type: "text",
        id: "employerAddress",
      },
      {
        placeholder: "Staff ID",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "staffId",
        isRequired: true,
      },
      {
        placeholder: "AHPC ID",
        icon: <CallOutlinedIcon />,
        type: "text",
        id: "ahpcID",
        isRequired: true,
      },
    ],
  },
  {
    title: "Account Security",
    subtitle: "Edit information Account security",
    data: [
      {
        placeholder: "Email",
        icon: <EmailOutlined />,
        type: "text",
        id: "email",
      },
      {
        placeholder: "Password",
        icon: <HttpsOutlinedIcon />,
        type: "password",
        id: "password",
      },
      {
        placeholder: "Re-type Password",
        icon: <HttpsOutlinedIcon />,
        type: "password",
        id: "retypePassword",
      },
    ],
  },
];
const employmentSteps = [
  {
    title: "Employment History: Working/Professional Experience;",
    subtitle: "Please start with the most current",

    data: [
      {
        placeholder: "Company/Organization/Institution/Firm",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "company",
      },
      {
        placeholder: "Region",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "region",
        menu: regions,
      },

      {
        placeholder: "Company Type",
        icon: <TransgenderOutlinedIcon />,
        type: "text",
        id: "companyType",
        menu: ["Government", "Private"],
      },
      {
        placeholder: "From",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "from",
      },
      {
        placeholder: "To",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "to",
      },
      {
        placeholder: "Job Title",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "jobTitle",
      },
    ],
  },
];
const educationSteps = [
  {
    title: "Educational Background and Qualifications",
    subtitle: "Please start with the most current",

    data: [
      {
        placeholder: "Name of School/institution",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "school",
      },

      {
        placeholder: "From",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "from",
      },
      {
        placeholder: "To",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "to",
      },

      {
        placeholder: "Qualification",
        icon: <AccountCircleOutlined />,
        type: "text",
        id: "qualification",
        menu: jobQualification,
      },
      {
        placeholder: "Year Obtained",
        icon: <EventAvailableOutlinedIcon />,
        type: "date",
        id: "yearObtained",
      },
    ],
  },
];
const specialtySteps = [
  {
    title: "Work Practice Specialty",
    subtitle: "Please indicate the main specialty in which you practice",
    data: [
      {
        placeholder: "Clinical Chemistry",
        id: "cliicalChemistry",
        name: "cliicalChemistry",
      },
      {
        placeholder: "Cytology",
        id: "cytology",
        name: "cytology",
      },
      {
        placeholder: "Hematology",
        id: "hematology",
        name: "hematology",
      },
      {
        placeholder: "Microbiology",
        id: "microbiology",
        name: "microbiology",
      },
      {
        placeholder: "Histopthology",
        id: "histopthology",
        name: "histopthology",
      },
      {
        placeholder: "Immunology",
        id: "immunology",
        name: "immunology",
      },
      {
        placeholder: "Virology",
        id: "virology",
        name: "virology",
      },
      {
        placeholder: "Parasitology",
        id: "parasitology",
        name: "parasitology",
      },
      {
        placeholder: "Genetics",
        id: "genetics",
        name: "genetics",
      },
      {
        placeholder: "Transfusion Science",
        id: "transfusionScience",
        name: "transfusionScience",
      },
      {
        placeholder: "Academia",
        id: "academia",
        name: "academia",
      },
      {
        placeholder: "Research",
        id: "research",
        name: "research",
      },
    ],
  },
];

const specialtyFormValues = {};
specialtySteps[0].data.forEach((item) => {
  specialtyFormValues[item.name] = false;
});

const securitySteps = [
  {
    title: "Change your password",
    subtitle: "Edit information on account security",
    data: [
      {
        placeholder: "Current Password",
        icon: <HttpsOutlinedIcon />,
        type: "password",
        id: "currentPassword",
      },
      {
        placeholder: "New Password",
        // icon: <VisibilityOff />,
        type: "password",
        id: "password",
      },
      {
        placeholder: "Confirm Password",
        icon: <HttpsOutlinedIcon />,
        type: "password",
        id: "retypePassword",
      },
    ],
  },
];

const eventRegSteps = {
  header: "Register for event",
  buttonText: "Register",
  initialValues: { email: "", firstName: "", lastName: "" },
  validation: eventRegSchema,
  data: [
    {
      placeholder: "First Name",
      icon: <AccountCircleOutlined />,
      type: "text",
      id: "firstName",
    },
    {
      placeholder: "Last Name",
      icon: <AccountCircleOutlined />,
      type: "text",
      id: "lastName",
    },
    {
      placeholder: "Email",
      icon: <EmailOutlined />,
      type: "text",
      id: "email",
    },
  ],
};
const passResetSteps = {
  header: "Reset your Password",
  buttonText: "Reset",
  initialValues: { email: "" },
  validation: resetInitialValidationSchema,
  data: [
    {
      placeholder: "Email",
      icon: <EmailOutlined />,
      type: "text",
      id: "email",
    },
  ],
};
const app_constants = {
  mail: "info@gamls.org",
};

export {
  events,
  regions,
  steps,
  news,
  blogs,
  partners,
  placeHolderText,
  routePaths,
  executives,
  meetingDays,
  resources,
  mission_vision,
  composition,
  memberBenefits,
  past_presidents,
  registrationSteps,
  pages,
  about,
  app_constants,
  securitySteps,
  specialtySteps,
  employmentSteps,
  educationSteps,
  specialtyFormValues,
  eventRegSteps,
  passResetSteps,
};
