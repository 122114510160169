import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  confirmPasswordReset,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  onSnapshot,
  deleteDoc,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import Swal from "sweetalert2";
import { mapAuthCodeToMessage } from "../utils/mapCodeToMessage";
import { clear, save } from "./auth/authSlice";

const firebaseConfig = {
  apiKey: "AIzaSyBVr-Bc59UddLW2LPnMW-e5DwABFo923d4",
  authDomain: "gamls-90281.firebaseapp.com",
  databaseURL: "https://gamls-90281-default-rtdb.firebaseio.com",
  projectId: "gamls-90281",
  storageBucket: "gamls-90281.appspot.com",
  messagingSenderId: "805142931162",
  appId: "1:805142931162:web:b14eb4ae576506cc4da7e3",
  measurementId: "G-HQ1SVZ1RPW",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const currentUser = auth.currentUser;
const db = getFirestore(app);
const storage = getStorage(app);

const registerWithEmailAndPassword = async (email, password, data) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      ...data,
      date: serverTimestamp(),
    });

    Swal.fire({
      title: "Sign Up Success",
      text: "Admin will confirm your information. The red icon on your profile avatar will change to green once the admin approves your access request",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Okay",
      confirmButtonColor: "#000000",
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Registration unsuccessful",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};

const sendPasswordReset = async (email, navigate) => {
  try {
    await sendPasswordResetEmail(auth, email);
    Swal.fire({
      title: "Success",
      text: "Password reset link sent!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Password reset error",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};
const passwordReset = async (oobCode, password, navigate) => {
  try {
    await confirmPasswordReset(auth, oobCode, password);
    Swal.fire({
      title: "Success",
      text: "Password reset Successful!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/login");
      }
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Password reset error",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};
const changePassword = async (currentPassword, newPassword, dispatch) => {
  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    );
    const authenticated = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );
    console.log(authenticated);
    if (authenticated) {
      updatePassword(auth.currentUser, newPassword)
        .then(async () => {
          console.log("success");
          Swal.fire({
            icon: "success",
            title: "Update successful",
            text: "Password successfully updated",
            confirmButtonColor: "#000000",
          });
          const userRef = doc(db, "users", auth.currentUser.uid);
          await setDoc(
            userRef,
            { date_modified: serverTimestamp() },
            { merge: true }
          ).then(() => {
            getUser(dispatch);
          });
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    }
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Update unsuccessful",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};

const logout = (dispatch) => {
  signOut(auth)
    .then(() => {
      localStorage.removeItem("firebaseUser");
      sessionStorage.removeItem("firebaseUser");
      dispatch(clear());
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Logout error",
        text: mapAuthCodeToMessage(error.code),
        confirmButtonColor: "#000000",
      });
    });
};

const getUser = async (dispatch) => {
  try {
    const docRef = doc(db, `users/${auth.currentUser.uid}`);
    const unsub = onSnapshot(docRef, (doc) => {
      dispatch(save(doc.data()));
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Action could not be completed. Kindly retry",
      confirmButtonColor: "#000000",
    });
  }
};

const deleteInfo = async (itemID, array) => {
  Swal.fire({
    title: "Do you want to delete this record?",
    text: "If you delete this record, you won't be able to recover it later.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#FD0113",
    cancelButtonColor: "#000",
    confirmButtonText: "Yes",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const dataArray = docSnap.data()[array];
        // Remove the item from the array
        const updatedArray = dataArray.filter((item) => item.id !== itemID);
        // Update the document in Firebase with the modified array
        await updateDoc(docRef, {
          [array]: updatedArray,
        })
          .then(() => {
            Swal.fire(
              "Success!",
              "Record is deleted",
              "success",
              false,
              "#FD0113"
            );
          })
          .catch((err) => {
            Swal.fire(
              "Something happened!",
              "Record wasn't deleted. Kindly try again later.",
              "error",
              false,
              "#FD0113"
            );
          });
      }
    }
  });
};

const setNotifRead = async (id) => {
  try {
    const docRef = doc(db, `cms/notifications/general`, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const readBy = docSnap.data()["readBy"];
      let newReadBy = [];
      if (Array.isArray(readBy)) {
        newReadBy = [...readBy];
      }

      if (!newReadBy.includes(auth.currentUser.email)) {
        newReadBy.push(auth.currentUser.email);
        console.log(newReadBy);
        await updateDoc(docRef, {
          readBy: newReadBy,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};
const eventRegisterUser = async (id, user, onClose) => {
  try {
    const docRef = doc(db, `cms/website/events`, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const reg = docSnap.data()["registrations"];
      let newReg = [];
      if (Array.isArray(reg)) {
        newReg = [...reg];
      }
      const hasEmail = newReg.some(
        (registration) => registration.email === user.email
      );
      if (!hasEmail) {
        onClose();
        user.date_of_reg = new Date();
        newReg.push({ ...user });

        await updateDoc(docRef, {
          registrations: [...newReg],
        }).then((res) => {
          Swal.fire({
            title: "Registered",
            text: "You have successfully registered for this event",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#000000",
          });
        });
      } else if (hasEmail) {
        onClose();
        Swal.fire({
          title: "Event Registration",
          text: "You are already registered. Do you want to cancel your registration?",
          icon: "alert",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#FD0113",
          cancelButtonColor: "#000",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          const updatedUserArray = newReg.filter(
            (registration) => registration.email !== user.email
          );

          if (result.isConfirmed) {
            await updateDoc(docRef, {
              registrations: [...updatedUserArray],
            }).then((res) => {
              Swal.fire({
                title: "Registration cancelled",
                text: "you have successfully cancelled your registration for this event",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#000000",
              });
            });
          }
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export {
  auth,
  db,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUser,
  storage,
  changePassword,
  passwordReset,
  deleteInfo,
  setNotifRead,
  eventRegisterUser,
};
