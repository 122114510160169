class User {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.firstName = obj.firstName != null ? obj.firstName : "";
    this.lastName = obj.lastName != null ? obj.lastName : "";
    this.gender = obj.gender != null ? obj.gender : "";
    this.birthDate = obj.birthDate != null ? obj.birthDate : "";
    this.maritalStatus = obj.maritalStatus != null ? obj.maritalStatus : "";
    this.email = obj.email != null ? obj.email : "";
    this.region = obj.region != null ? obj.region : "";
    this.nationality = obj.nationality != null ? obj.nationality : "";
    this.address = obj.address != null ? obj.address : "";
    this.phone = obj.phone != null ? obj.phone : "";
    this.academicLevel = obj.academicLevel != null ? obj.academicLevel : "";
    this.specialty = obj.specialty != null ? obj.specialty : "";
    this.currentEmployer =
      obj.currentEmployer != null ? obj.currentEmployer : "";
    this.employerAddress =
      obj.employerAddress != null ? obj.employerAddress : "";
    this.staffId = obj.staffId != null ? obj.staffId : "";
    this.date = obj.date != null ? obj.date : "";
    this.activated = obj.activated != null ? obj.activated : false;
    this.memberId = obj.memberId != null ? obj.memberId : "";
  }
}

export const userConverter = {
  toFirestore: (user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      birthDate: user.birthDate,
      email: user.email,
      maritalStatus: user.maritalStatus,
      region: user.region,
      nationality: user.nationality,
      address: user.address,
      phone: user.phone,
      academicLevel: user.academicLevel,
      specialty: user.specialty,
      currentEmployer: user.currentEmployer,
      employerAddress: user.employerAddress,
      staffId: user.staffId,
      date: user.date,
      activated: user.activated,
      memberId: user.memberId,
    };
  },
  fromFirestore: (snapshot, options) => {
    const user = snapshot.data(options);
    return new User({
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      birthDate: user.birthDate,
      email: user.email,
      maritalStatus: user.maritalStatus,
      region: user.region,
      nationality: user.nationality,
      address: user.address,
      phone: user.phone,
      academicLevel: user.academicLevel,
      specialty: user.specialty,
      currentEmployer: user.currentEmployer,
      employerAddress: user.employerAddress,
      staffId: user.staffId,
      date: user.date.seconds
        ? new Date(user.date.seconds * 1000).toLocaleDateString()
        : user.date,
      activated: user.activated,
      memberId: user.memberId,
    });
  },
};

export class Personal {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.firstName = obj.firstName != null ? obj.firstName : "";
    this.lastName = obj.lastName != null ? obj.lastName : "";
    this.gender = obj.gender != null ? obj.gender : "";
    this.birthDate = obj.birthDate != null ? obj.birthDate : "";
    this.maritalStatus = obj.maritalStatus != null ? obj.maritalStatus : "";
    this.email = obj.email != null ? obj.email : "";
  }
}

export class Contact {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.region = obj.region != null ? obj.region : "";
    this.nationality = obj.nationality != null ? obj.nationality : "";
    this.address = obj.address != null ? obj.address : "";
    this.phone = obj.phone != null ? obj.phone : "";
  }
}

export class Work {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.academicLevel = obj.academicLevel != null ? obj.academicLevel : "";
    this.specialty = obj.specialty != null ? obj.specialty : "";
    this.currentEmployer =
      obj.currentEmployer != null ? obj.currentEmployer : "";
    this.employerAddress =
      obj.employerAddress != null ? obj.employerAddress : "";
    this.staffId = obj.staffId != null ? obj.staffId : "";
  }
}

export class Login {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.email = obj.email != null ? obj.email : "";
    this.password = obj.password != null ? obj.password : "";
    this.retypePassword = obj.retypePassword != null ? obj.retypePassword : "";
    this.activated = obj.activated != null ? obj.activated : false;
  }
}

export default User;
