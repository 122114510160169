import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Image from "mui-image";
import React, { useCallback } from "react";
import { ArrowBackIos, GamlsLogoRed, Hero } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword } from "../../data/firebase";
import TransitionAlerts from "../../components/transitionAlerts";
import { PersonalDetails } from "./registrationSteps/personal";
import { ContactDetails } from "./registrationSteps/contact";
import { WorkDetails } from "./registrationSteps/work";
import { Credential } from "./registrationSteps/credentials";
import { signInWithEmailAndPassword } from "firebase/auth";
import InputText from "../../components/form/inputText";
import { mapAuthCodeToMessage } from "../../utils/mapCodeToMessage";
import { PrimaryButton, TextButton } from "../../components/buttons";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import User, { Contact, Login, Personal, Work } from "../../data/models/user";
import {
  contactValidationSchema,
  loginValidationSchema,
  personalValidationSchema,
  workValidationSchema,
} from "../../utils/validationSchema";
import CustomizedStepper from "./stepper";
import { LoginContent } from "./loginContent";

const loginProps = {
  title: "Welcome",
  subtitle: " Enter your Email and Password to log in.",
};
const steps = [
  {
    model: new Personal(),
    validationSchema: personalValidationSchema,
  },
  {
    model: new Contact(),
    validationSchema: contactValidationSchema,
  },
  {
    model: new Work(),
    validationSchema: workValidationSchema,
  },
  {
    model: new Login(),
    validationSchema: loginValidationSchema,
  },
];

const RegisterContent = ({ stage, navigate }) => {
  const shouldGoNext = stage !== steps.length - 1;
  const formik = useFormik({
    initialValues: steps[stage].model,
    validationSchema: steps[stage].validationSchema,
    onSubmit: async (values, actions) => {
      console.log("Eweew");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (shouldGoNext) {
        navigate(`/login?reg_stg=${stage + 1}`);
      } else {
        const user = new User({ ...values });
        registerWithEmailAndPassword(values.email, values.password, user);
      }
    },
  });

  const getComponent = useCallback(() => {
    switch (stage) {
      case 1:
        return <ContactDetails formik={formik} />;
      case 2:
        return <WorkDetails formik={formik} />;
      case 3:
        return <Credential formik={formik} />;
      default:
        return <PersonalDetails formik={formik} />;
    }
  }, [formik, stage]);

  return (
    <Stack
      p={{ xs: "10px", md: "8%" }}
      spacing={{ xs: 2, xl: 4, md: 2, lg: 3 }}
      component={"form"}
      onSubmit={formik.handleSubmit}
      sx={{ overflow: "scroll" }}
    >
      <CustomizedStepper activeStep={stage} />

      <>{getComponent()}</>

      <Stack direction={"row"} spacing={2}>
        {stage === 0 ? null : (
          <PrimaryButton
            bg={"black"}
            onClick={() => navigate(-1)}
            loading={formik.loading}
          >
            Previous
          </PrimaryButton>
        )}
        <PrimaryButton
          type={"submit"}
          loading={formik.loading}
          disabled={formik.disabled}
        >
          {shouldGoNext ? "Next" : "Submit"}
        </PrimaryButton>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant={"button"} sx={{ textTransform: "initial" }}>
          Already have an account?
        </Typography>
        <TextButton color={"primary"} onClick={() => navigate("/login")}>
          Login here
        </TextButton>
      </Stack>
    </Stack>
  );
};

export const Content = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [stage, setStage] = React.useState(null);

  React.useEffect(() => {
    const params = new URLSearchParams(search);
    setStage(params.get("reg_stg"));
  }, [search]);

  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  const handleBackClick = useCallback(() => navigate("/"), [navigate]);

  return (
    <Stack className={"highter"} direction={"row"} sx={{ overflow: "hidden" }}>
      <Box flex={1} sx={{ display: { xs: "none", sm: "inherit" } }}>
        <Image width={"100%"} height={"100vh"} src={Hero} />
      </Box>
      <Stack flex={{ xs: 1, md: 0.5 }} className={matches ? null : "login-bg"}>
        <Stack p={"5%"} spacing={2} alignItems={"flex-start"}>
          <Tooltip title={"Back to Home"}>
            <IconButton color={"primary"} onClick={handleBackClick}>
              <ArrowBackIos />
            </IconButton>
          </Tooltip>
          <Box
            component={"img"}
            src={GamlsLogoRed}
            sx={{
              width: { xs: 160, md: 220, sm: 200, lg: 200 },
              height: { xs: 70, md: 100, sm: 80, lg: 90 },
            }}
          />
        </Stack>

        {stage ? (
          <RegisterContent navigate={navigate} stage={parseInt(stage)} />
        ) : (
          <LoginContent {...loginProps} navigate={navigate} showRegister />
        )}
      </Stack>
    </Stack>
  );
};
