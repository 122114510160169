import * as yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const passValidation = () => {
  return {
    password: yup
      .string()
      .matches(passwordRules, { message: "Please create a stronger password" })
      .required("Password is required"),
    retypePassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please retype password"),
  };
};

const loginValidationSchema = yup.object().shape({
  email: yup.string().required("Required").email("Please enter a valid email"),
  ...passValidation(),
});
const resetInitialValidationSchema = yup.object().shape({
  email: yup.string().required("Required").email("Please enter a valid email"),
});

const resetValidationSchema = yup.object().shape({
  ...passValidation(),
});
const changePasswordValidationSchema = yup.object().shape({
  currentPassword: yup.string().required("Password is required"),
  ...passValidation(yup),
});

const personalValidationSchema = yup.object().shape({
  firstName: yup.string().required("Firstname is  required"),
  lastName: yup.string().required("Lastname is required"),
  gender: yup.string().required("Please select gender"),
  birthDate: yup.string().required("Date of birth is required"),
  maritalStatus: yup.string().required("Please select marital status"),
});
const eventRegSchema = yup.object().shape({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().required("Required").email("Please enter a valid email"),
});

const contactValidationSchema = yup.object().shape({
  region: yup.string().required("Please select region"),
  nationality: yup.string().required("Nationality is required"),
  address: yup.string().required("Kindly provide your address"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
});

const workValidationSchema = yup.object().shape({
  academicLevel: yup.string().required("Kindly select one"),
  specialty: yup.string().required("Specialty is required"),
  currentEmployer: yup.string().required("Please provide name of employer"),
  employerAddress: yup.string().required("Required"),
  // staffId: yup.string().required("Required"),
});

const userValidationSchema = yup.object().shape({
  firstName: yup.string().max(25, "Must be 25 characters or less"),
  lastName: yup.string().max(25, "Must be 25 characters or less"),
  gender: yup.string().max(25, "Must be 25 characters or less"),
  birthDate: yup.string().max(25, "Must be 25 characters or less"),
  email: yup.string().max(25, "Must be 25 characters or less"),
  maritalStatus: yup.string().max(25, "Must be 25 characters or less"),
  region: yup.string().max(25, "Must be 25 characters or less"),
  nationality: yup.string().max(25, "Must be 25 characters or less"),
  address: yup.string().max(25, "Must be 25 characters or less"),
  phone: yup.string().max(25, "Must be 25 characters or less"),
  specialty: yup.string().max(25, "Must be 25 characters or less"),
  employer: yup.string().max(25, "Must be 25 characters or less"),
  employerAddress: yup.string().max(25, "Must be 25 characters or less"),
  id: yup.string().max(25, "Must be 25 characters or less"),
  grade: yup.string().max(25, "Must be 25 characters or less"),
  regDate: yup.string().max(25, "Must be 25 characters or less"),
  isAuth: yup.string().max(25, "Must be 25 characters or less"),
});
const profileUpdateValidationSchema = yup.object().shape({
  firstName: yup.string().max(25, "Must be 25 characters or less"),
  lastName: yup.string().max(25, "Must be 25 characters or less"),
  gender: yup.string().max(25, "Must be 25 characters or less"),
  birthDate: yup.string().max(25, "Must be 25 characters or less"),

  maritalStatus: yup.string().max(25, "Must be 25 characters or less"),
  region: yup.string().max(25, "Must be 25 characters or less"),
  nationality: yup.string().max(25, "Must be 25 characters or less"),
  address: yup.string().max(25, "Must be 25 characters or less"),
  phone: yup.string().max(25, "Must be 25 characters or less"),
  ghanaCard: yup.string().required("").max(25, "Must be 25 characters or less"),
  kinContact: yup
    .string()
    .required("")
    .max(25, "Must be 25 characters or less"),
  nextOfKin: yup.string().required("").max(25, "Must be 25 characters or less"),
  academicLevel: yup.string().max(25, "Must be 25 characters or less"),
  specialty: yup.string().max(25, "Must be 25 characters or less"),
  currentEmployer: yup.string().max(25, "Must be 25 characters or less"),
  employerAddress: yup.string().max(25, "Must be 25 characters or less"),
  ahpcID: yup.string().required("").max(25, "Must be 25 characters or less"),
  staffId: yup.string().required("").max(25, "Must be 25 characters or less"),

  // employer: yup.string().max(25, "Must be 25 characters or less"),
  // employerAddress: yup.string().max(25, "Must be 25 characters or less"),
  // id: yup.string().max(25, "Must be 25 characters or less"),
  // grade: yup.string().max(25, "Must be 25 characters or less"),
});

export {
  loginValidationSchema,
  personalValidationSchema,
  contactValidationSchema,
  workValidationSchema,
  userValidationSchema,
  changePasswordValidationSchema,
  resetValidationSchema,
  profileUpdateValidationSchema,
  eventRegSchema,
  resetInitialValidationSchema,
};
