import InputText from "../../../components/form/inputText";
import CustomSelect from "../../../components/form/customSelect";

export const PersonalDetails = ({ formik }) => {
  return (
    <>
      <InputText
        placeholder={"First Name"}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        helperText={formik.errors.firstName}
        name={"firstName"}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
      />
      <InputText
        placeholder={"Last Name"}
        value={formik.values.lastName}
        onChange={formik.handleChange}
        helperText={formik.errors.lastName}
        name={"lastName"}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
      />
      <CustomSelect
        helperText={formik.errors.gender}
        placeholder={"Gender"}
        data={["Male", "Female"]}
        onSelect={(e) => formik.setFieldValue("gender", e)}
        error={formik.touched.gender && Boolean(formik.errors.gender)}
        defaultValue={formik.values.gender}
      />
      <InputText
        placeholder={"Date of Birth"}
        value={formik.values.birthDate}
        onChange={formik.handleChange}
        helperText={formik.errors.birthDate}
        name={"birthDate"}
        type={"date"}
        error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
      />
      <CustomSelect
        helperText={formik.errors.maritalStatus}
        placeholder={"Marital Status"}
        data={["Single", "Married", "Divorced"]}
        onSelect={(e) => formik.setFieldValue("maritalStatus", e)}
        error={
          formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)
        }
        defaultValue={formik.values.maritalStatus}
      />
    </>
  );
};
